import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Continuidade de Negócios</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Continuidade de Negócios</H5>
                <ScrollAnim.Link
                  to='proposito'
                  offsetTop='120'
                >
                  Propósito
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='diretrizes'
                  offsetTop='120'
                >
                  Diretrizes
                </ScrollAnim.Link>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div id='proposito'>
                  <H6>1. Propósito</H6>
                  <Paragraph>Assegurar a continuidade das operações da organização na eventualidade de uma interrupção prolongada dos recursos que dão suporte à realização dessas operações (equipamentos, sistemas de informação, instalações, pessoal e informações), provendo uma estrutura estratégica e operacional adequada para:</Paragraph>
                  <ul>
                    <li>&#9679; <span>Melhorar a capacidade de recuperação de uma interrupção inesperada:</span> mitigando os riscos de interrupções e diminuindo o tempo de resposta a possíveis incidentes;</li>
                    <li>&#9679; <span>Recuperar a operacionalidade:</span> com base em um método sistemático para retorno em um tempo aceitável dos serviços críticos após um incidente; e</li>
                    <li>&#9679; <span>Obter capacidade de gerenciar uma interrupção ao negócio:</span> a fim de reduzir os impactos na operação, para assim proteger o negócio e sua reputação.</li>
                  </ul>
                  <Paragraph><span>1.1. Escopo</span></Paragraph>
                  <Paragraph>Esta política de continuidade de negócios abrange os processos de negócio e as operações do Data Center JPA da HostDime Brasil, no que se refere as Instalações, Sistemas, Equipamentos, Processos e Pessoas, promovendo possibilidades de retomada em tempo hábil e em nível aceitável para as atividades críticas do negócio, em caso de interrupção por falhas ou desastres significativos.</Paragraph>
                  <Paragraph>Esta Política de Continuidade de Negócios (PCN) tem como base o escopo do Sistema de Gestão Integrado (SGI) da HostDime.</Paragraph>
                  <Paragraph>A Política de Continuidade de Negócios da HostDime está estabelecido sobre os seguintes pilares operacionais:</Paragraph>
                  <ul>
                    <li>1. Infraestrutura física;</li>
                    <li>2. Infraestrutura de TI;</li>
                    <li>3. Equipamentos, arquiteturas e sistemas de TI, quando aplicável;</li>
                    <li>4. Pessoas, e;</li>
                    <li>5. Processos da organização.</li>
                  </ul>
                  <Paragraph>A perda, mesmo que parcial, de um ou mais dos aspectos acima, podem provocar uma interrupção de tal forma que necessite o acionamento do(s) Plano(s) de Continuidade de Negócios da organização.</Paragraph>
                </Div>
                <Div id='diretrizes'>
                  <H6 style={{ marginTop: 24 }}>2. Diretrizes</H6>
                  <Paragraph><span>2.1 Gestão de Continuidade</span></Paragraph>
                  <Paragraph>O processo de Gestão de Continuidade se desenvolve com base nas seguintes atividades:</Paragraph>
                  <ul>
                    <li>1. Identificar através da Análise de Impacto no Negócio (BIA), os processos necessários para a recuperação do negócio;</li>
                    <li>2. Levantar as condições mínimas de trabalho para cada processo;</li>
                    <li>3. Identificar os ativos “de negócio” necessários na condição anterior;</li>
                    <li>4. Definir os responsáveis pelos processos quando em regime de continuidade do negócio;</li>
                    <li>5. Educar, treinar, conscientizar, comunicar e prover condições para que todos os profissionais compreendam suas responsabilidades perante a Continuidade de Negócios;</li>
                    <li>6. Identificar os cenários que podem levar à ruptura do negócio;</li>
                    <li>7. Elaborar os planos de recuperação operacionais para cada cenário de ruptura de negócio;</li>
                    <li>8. Testar os planos definidos para garantir o adequado funcionamento de cada um, e suas interações;</li>
                    <li>9. Produzir relatório do programa de exercícios a fim de analisar criticamente os resultados e identificar melhorias.</li>
                  </ul>
                </Div>
              </Div>    
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Continuidade de Negócios' />
      <PageTemplate
        title='Política de Continuidade de Negócios'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
